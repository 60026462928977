import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stele"
    }}>{`Stele`}</h1>
    <h2 {...{
      "id": "what-is-stele"
    }}>{`What is stele?`}</h2>
    <p>{`Stele is a suite of tools for building international applications in modern
single page apps. The name comes from the Rosetta Stone, which was a Stele,
meaning a giant stone (or wooden) monument, often with some sort of decree.
Really, it was the coolest name we could think of that was not taken on NPM.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`Currently our only dependency to use Stele is babel. If you are not currently
using babel for your build, you can
`}<a parentName="p" {...{
        "href": "https://babeljs.io/en/setup"
      }}>{`get started here`}</a></p>
    <p>{`First install Stele:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm i -D @patreon/stele
`}</code></pre>
    <p>{`Then add it as a plugin to your babel config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { plugin } = require('@patreon/stele')

module.exports = {
    presets: ['@babel/preset-react'],
    plugins: [[plugin, options]],
}
`}</code></pre>
    <h2 {...{
      "id": "the-problems"
    }}>{`The Problems`}</h2>
    <p>{`The ecosystem in javascript for internationalization is quite daunting to first
look into. At Patreon we had a few main goals for starting our
internationalization journey.`}</p>
    <ol>
      <li parentName="ol">{`We have thousands of untranslated strings.`}</li>
      <li parentName="ol">{`We have dozens of developers working at the same time.`}</li>
      <li parentName="ol">{`We have tens of thousands of strings and do not want to bloat our
application by sending down strings we do not need.`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "https://xkcd.com/927/"
      }}>{`Our goal is not to create a new standard to replace all standards in the JS ecosystem.`}</a>{`
We wanted to solve the problems we were facing at Patreon and share our
solution. If you are facing a different set of problems this may not be the
right solution for you or your product.`}</p>
    <h4 {...{
      "id": "what-we-needed-in-an-internationalizable-library"
    }}>{`What we needed in an internationalizable library`}</h4>
    <ol>
      <li parentName="ol">{`Transitioning strings should be as easy as possible.`}</li>
      <li parentName="ol">{`Writing translatable strings should be as easy as possible.`}</li>
      <li parentName="ol">{`Use the power of our compiler to alleviate run time checks of strings.`}</li>
      <li parentName="ol">{`Easily enforce conversion of our site`}</li>
    </ol>
    <h2 {...{
      "id": "the-parts"
    }}>{`The Parts`}</h2>
    <ol>
      <li parentName="ol">{`Babel plugin for compiling individual languages`}</li>
      <li parentName="ol">{`Webpack plugin for extracting a JSON file (coming soon!)`}</li>
      <li parentName="ol">{`An abstraction of `}<a parentName="li" {...{
          "href": "https://formatjs.io/guides/message-syntax/"
        }}>{`ICU strings`}</a>{`
to aid in writing more complex international strings`}<ol parentName="li">
          <li parentName="ol">{`Documentation on those abstractions is available
`}<a parentName="li" {...{
              "href": "src/components/README.md"
            }}>{`here`}</a></li>
        </ol></li>
    </ol>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h2>
    <p>{`This library is heavily inspired by
`}<a parentName="p" {...{
        "href": "https://github.com/iosphere/elm-i18n/"
      }}>{`elm-i18n`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/webpack-contrib/i18n-webpack-plugin/blob/master/src/index.js"
      }}>{`i18n-webpack-plugin`}</a>{`.
This library co-evolved with `}<a parentName="p" {...{
        "href": "https://github.com/lingui/js-lingui"
      }}>{`js-lingui`}</a>{`
with similar ideas. When babel starts, so does Stele:`}</p>
    <ol>
      <li parentName="ol">{`Extracts that default language string to a JSON store`}</li>
      <li parentName="ol">{`Appends a `}<inlineCode parentName="li">{`[defaultLanguage]-[defaultLocale].json`}</inlineCode>{` to your webpack build
(coming soon!)`}</li>
      <li parentName="ol">{`Looks up strings from `}<inlineCode parentName="li">{`[currentLanguage]-[currentLocale].json`}</inlineCode><ol parentName="li">
          <li parentName="ol">{`Replace strings directly for namespaced functions`}</li>
          <li parentName="ol">{`Replace strings through a reverse compiler for React components`}</li>
        </ol></li>
      <li parentName="ol">{`Finally emit the new javascript file.`}</li>
    </ol>
    <h2 {...{
      "id": "translating-react-components"
    }}>{`Translating React components.`}</h2>
    <p>{`Most of your strings will likely exist through React components. Stele makes
transitioning these components easy.`}</p>
    <p>{`Given:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text>
    {props.food} is just a {props.kind} calzone
</Text>
`}</code></pre>
    <p>{`Let's say that you have a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component for rendering out user facing copy on
your website. We need a way to tell the compiler that this text should be
extracted to a JSON file and not just normal layout text. The way we will do
this in react is through a prop on your component, let's call it `}<inlineCode parentName="p">{`intl`}</inlineCode>{`. In
order to get the above string translated in Stele the migration is simple:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text intl>
    {props.food} is just a {props.kind} calzone
</Text>
`}</code></pre>
    <p>{`This produces the JSON file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "{food} is just a {kind} calzone": "{food} is just a {kind} calzone"
}
`}</code></pre>
    <h3 {...{
      "id": "more-complex-and-nested-examples"
    }}>{`More complex and nested examples`}</h3>
    <p>{`Let's say you have a string that has some italic text in it`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text>
    Tom considers himself a <Text italic>foodie</Text>
</Text>
`}</code></pre>
    <p>{`We want this entire string to be translatable, rather than translating "Tom
considers himself a" and "foodie" stele instead extracts this as a single string
once it is marked for translation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text intl>Tom considers himself a <Text italic>foodie</Text></Text>
// creates json:
{"Tom considers himself a <1>foodie</1>": "Tom considers himself a <1>foodie</1>"}
`}</code></pre>
    <p>{`Stele keeps an internal order for HTML tags when compiling back to the language
it cares about. This way in case the order changes, stele can put the right JSX
in the correct spots.`}</p>
    <h3 {...{
      "id": "built-in-components"
    }}>{`Built in components`}</h3>
    <p>{`Stele tries to support all
`}<a parentName="p" {...{
        "href": "https://formatjs.io/guides/message-syntax/"
      }}>{`ICU formats`}</a>{` like `}<inlineCode parentName="p">{`select`}</inlineCode>{`,
`}<inlineCode parentName="p">{`number`}</inlineCode>{`, `}<inlineCode parentName="p">{`date`}</inlineCode>{` and `}<inlineCode parentName="p">{`plural`}</inlineCode>{`. Using these in JSX is done through React
components maintained by stele.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text>
    Wait, now we're on an island? With
    { props.kidCount === 1 ? 'a kid' : 'kids') }
</Text>
`}</code></pre>
    <p>{`Pluralizing this string in Stele takes a little bit more work than our previous
examples. The big problem here is that while english only has 2 ways to
pluralize something (One or Other), many languages have multiple. To handle this
we simply need to use the plural component that Stele provides:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text intl intl-description="Phrase with plural check on main page">
    Wait, now we're on an island? With
    <Plural value={kidCount} one="a kid" other="# kids" />?
</Text>
`}</code></pre>
    <p>{`This produces the ICU message we want to send our translators:`}</p>
    <pre><code parentName="pre" {...{}}>{`Wait, now we're on an island. With {kidCount, plural, one {a kid} other {# kids}}?
`}</code></pre>
    <p>{`The translators will send back a string in a new language that might have
different kinds of plurals, for instance in russian the string sent back might
look like:`}</p>
    <pre><code parentName="pre" {...{}}>{`Подождите, теперь мы на острове. {kidCount, plural, {
  =0 {Без детей}
  one {C одним ребенком}
  few {C # детьми}
  many {C # детьми}
}}
`}</code></pre>
    <p>{`When stele is compiling the russian version of the site, we compile that in to
JSX for you that looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text>
    Подождите, теперь мы на острове?
    <Plural
        value={kidCount}
        zero="Без детей"
        ones="C одним ребенком"
        few="C # детьми"
        many="C # детьми"
    />?
</Text>
`}</code></pre>
    <h2 {...{
      "id": "translating-strings-outside-of-react-components"
    }}>{`Translating strings outside of react components.`}</h2>
    <p>{`Let's say you have an input on your page with a placeholder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<label>What if I get drunk and I talk about Darfur too much?</label>
<input placeholder="Have a practice date" />
`}</code></pre>
    <p>{`Internationalizing the label is easy, however internationalizing the placeholder
is just as easy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<label>
    <Text intl>What if I get drunk and I talk about Darfur too much?</Text>
</label>
<input placeholder={__('Have a practice date')} />
`}</code></pre>
    <h4 {...{
      "id": "dynamic-strings"
    }}>{`Dynamic strings`}</h4>
    <p>{`Sometimes you might need to include variables in your strings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const whichWife = \`No, my other ex-wife Tammy - Tammy \${whichTammy}.\`
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://github.com/yahoo/intl-messageformat#intlmessageformat-constructor"
      }}>{`Internationalizing this follows standard ICU string rules`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const whichWife = __('No, my other ex-wife Tammy - Tammy {whichTammy}', {
    whichTammy: whichTammy,
})
`}</code></pre>
    <p>{`These strings are extracted directly with no manipulations, unlike JSX strings.
We call this function the `}<inlineCode parentName="p">{`dunder`}</inlineCode>{` function as a portmanteau of "double" and
"underscore".`}</p>
    <h2 {...{
      "id": "limitations-in-jsx"
    }}>{`Limitations in JSX`}</h2>
    <p>{`While this appears to be a silver bullet, there are few common paradigms in JSX
that will not work in Stele. Namely that no run time specific code can be put in
our `}<inlineCode parentName="p">{`Text`}</inlineCode>{` as children and also that strings are not computed at compile time,
like they might in more idiomatic React code.`}</p>
    <h4 {...{
      "id": "the-rule"
    }}>{`The rule`}</h4>
    <p>{`Strings in JSX must be wholly comprised of Stele components or string literals.
Outside of JSX the entire string you want to display to a user must be a string
literal.`}</p>
    <h3 {...{
      "id": "an-example-of-how-to-refactor-an-existing-text-component"
    }}>{`An example of how to refactor an existing `}<inlineCode parentName="h3">{`Text`}</inlineCode>{` component`}</h3>
    <p>{`Let's say we had a string that computes a different string based on a variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text intl>But babe, {isBenWyatt ? 'calzone' : 'pizza'}</Text>
`}</code></pre>
    <p>{`would only produce the message:`}</p>
    <pre><code parentName="pre" {...{}}>{`"But babe, "
`}</code></pre>
    <p>{`because the above code relies on runtime behavior to create a string. All
strings in Stele must be complete sentences, without logic.`}</p>
    <p>{`Instead you could encompass both strings in their entirety inside of the
ternary:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{
    isBenWyatt ? (
        <Text intl>But babe, calzone</Text>
    ) : (
        <Text intl>But babe, pizza</Text>
    )
}
`}</code></pre>
    <p>{`or, if you'd like, you can even use the dunder function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Text>{isBenWyatt ? __('But babe, calzone') : __('But babe, pizza')}</Text>
`}</code></pre>
    <p>{`Which is the valid run time version of the previous string.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      